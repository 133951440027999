@if (errorMessage()) {
  <div class="w-full">
    <div
      @fadeInOut
      role="alert"
      class="alert pb-0 px-0 overflow-hidden alert-error flex flex-col max-w-sm absolute top-10 lg:top-24 lg:right-24 right-10 z-[1001] shadow-lg"
    >
      <div class="flex w-full items-center justify-start px-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 shrink-0 stroke-current mr-4"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>{{ errorMessage() }}</span>
      </div>

      <progress
        class="progress w-full"
        [value]="errorCounter()"
        max="100"
      ></progress>
    </div>
  </div>
}

@if (successMessage()) {
  <div class="w-full">
    <div
      @fadeInOut
      role="alert"
      class="alert pb-0 px-0 overflow-hidden alert-success flex flex-col max-w-sm absolute top-10 lg:top-24 lg:right-24 right-10 z-[1001] shadow-lg"
    >
      <div class="flex w-full items-center justify-start px-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 shrink-0 stroke-current mr-4"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>{{ successMessage() }}</span>
      </div>

      <progress
        class="progress w-full"
        [value]="successCounter()"
        max="100"
      ></progress>
    </div>
  </div>
}
