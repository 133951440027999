import { ErrorHandlerService } from './../../../core/services/error-handler.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SuccessHandlerService } from '../../../core/services/successHandler.service';

@Component({
  selector: 'app-alert-handler',
  standalone: true,
  imports: [],
  templateUrl: './alert-handler.component.html',
  styleUrl: './alert-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('250ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AlertHandlerComponent {
  error = inject(ErrorHandlerService);
  success = inject(SuccessHandlerService);

  errorMessage = this.error.errorMessage;
  errorCounter = this.error.counter;

  successMessage = this.success.successMessage;
  successCounter = this.success.counter;
}
