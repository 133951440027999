import { Component, input, OnInit, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CloseOnOutsideClickDirective } from '../../../../directives/close-on-outside-click.directive';

@Component({
  selector: 'app-column-dropdown',
  standalone: true,
  imports: [TranslateModule, CloseOnOutsideClickDirective],
  templateUrl: './column-dropdown.component.html',
  styleUrl: './column-dropdown.component.scss',
})
export class ColumnDropdownComponent implements OnInit {
  options$$ = signal<any[][]>([]);

  ngOnInit(): void {
    this.options$$.set(this.getColumns(this.options(), this.options().length));
  }
  options = input<any>();
  availableFilter = input<number[]>([]);
  label = input<string>('');

  select = output<any>();
  remove = output<any>();
  activefilter = input<any[]>([]);

  getColumns(items: any[], itemsPerColumn: number): any[][] {
    const columns = [];
    for (let i = 0; i < items.length; i += itemsPerColumn) {
      columns.push(items.slice(i, i + itemsPerColumn));
    }
    return columns;
  }

  onCheck(event: any, item: any) {
    if (event.target.checked) {
      this.select.emit(this.getItem(item));
    } else {
      this.remove.emit(this.getItem(item));
    }
  }

  getItem(item: any) {
    switch (this.label()) {
      case 'pilot':
        return {
          id: item.id,
          name: `${item.firstname[0]}. ${item.lastname}`,
          type: 'pilot',
        };
      case 'lineage':
        return { id: item.id, name: item.name, type: 'lineage' };
      case 'nature':
        return { id: item.id, name: item.name, type: 'nature' };
      case 'severity':
        return { id: item, name: item, type: 'severity' };
    }

    return null;
  }

  isChecked(item: any) {
    if (this.label() === 'severity') {
      return this.activefilter().some((e) => e === item);
    }
    return this.activefilter().some((e) => e.id === item.id);
  }
}
