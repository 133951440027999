<div
  class="container xl:px-4 h-full overflow-auto flex flex-wrap gap-3 content-start"
>
  @for (item of list(); track $index) {
    <div
      class="badge badge-outline p-4 flex justify-between min-w-24 bg-white border-gray-300 rounded-lg text-xs"
    >
      @if (item.type === "status") {
        <span [innerHTML]="item.name | iconStatus"></span>
      } @else {
        {{ item.name | textShorter: 30 }}
      }

      <svg
        (click)="remove.emit(item)"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="size-6 ml-4 text-body cursor-pointer hover:text-gray-500"
      >
        <path
          fill-rule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  }
</div>
