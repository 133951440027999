<div
  tabindex="0"
  class="dropdown dropdown-hover w-full text-body"
  appCloseOnOutsideClick
  [class.btn-disabled]="!options().length"
>
  <div class="indicator w-full">
    @if (activefilter().length > 0) {
      <span class="indicator-item badge badge-primary text-white">
        {{ activefilter().length }}</span
      >
    }

    <div class="w-full">
      <div
        tabindex="0"
        class="btn bg-white border-gray-100 border flex justify-between min-w-36"
      >
        <div class="flex gap-4 items-center">
          @switch (label()) {
            @case ("pilot") {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6 fill-s0"
              >
                <path
                  d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z"
                />
              </svg>
            }
            @case ("lineage") {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6 fill-s0"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 9a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 9Zm0 6.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                  clip-rule="evenodd"
                />
              </svg>
            }
            @case ("nature") {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6 fill-s0"
              >
                <path
                  d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z"
                />
              </svg>
            }
            @case ("severity") {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6 fill-s0"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            }
          }

          <span class="text-body"> {{ "filters." + label() | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    tabindex="0"
    class="dropdown-content bg-white rounded-box z-[999] p-2 shadow min-w-full md:w-72 xl:w-max max-h-96 overflow-auto"
  >
    <div class="flex flex-wrap gap-4">
      @for (column of options$$(); track $index) {
        <ul tabindex="0">
          @for (item of column; track $index) {
            <li class="m-4">
              <label
                class="cursor-pointer flex items-center space-x-2 text-body"
              >
                <input
                  type="checkbox"
                  class="checkbox"
                  (change)="onCheck($event, item)"
                  [checked]="isChecked(item)"
                  [id]="['pilot', 'lineage'].includes(label()) ? item.id : item"
                />
                <span>
                  @if (label() === "pilot") {
                    {{ item.firstname }}
                    {{ item.lastname }}
                  } @else if (["lineage", "nature"].includes(label())) {
                    {{ item.name }}
                  } @else {
                    {{ item }}
                  }
                </span>
              </label>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>
