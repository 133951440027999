import { NavigationStateService } from './navigation-state.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  AuthType,
  ChangePasswordType,
  ResetPasswordType,
} from '../models/auth-type.model';
import { finalize, tap } from 'rxjs';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { LoginResponse } from '../models/login-response.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #URL = `${environment.apiUrl}`;
  readonly #http = inject(HttpClient);
  readonly #tokenService = inject(TokenService);
  readonly #router = inject(Router);
  readonly #navigationState = inject(NavigationStateService);
  get isLogged() {
    return !!this.#tokenService.getToken();
  }

  login(values: AuthType) {
    const httpParams = new HttpParams()

      .append('username', values.username)
      .append('password', values.password);
    return this.#http
      .post<LoginResponse>(`${this.#URL}/login`, httpParams)
      .pipe(
        tap((res) => {
          this.#navigationState.setProjectId(
            res.user?.favorite_project ?? null,
          );

          if (res.result === 'success') {
            this.#tokenService.setToken({ token: res.token, user: res.user });
          }
        }),
        finalize(() => {
          this.#router.navigate(['home']);
        }),
      );
  }

  logout() {
    return this.#http.get(`${this.#URL}/logout`).pipe(
      tap(() => this.#tokenService.removeToken()),
      finalize(() => {
        this.#router.navigate(['/']);
      }),
    );
  }

  forgotPassword(username: ResetPasswordType) {
    return this.#http.post(`${this.#URL}/forgotpass`, username);
  }

  changePassword(res: ChangePasswordType) {
    const httpParams = new HttpParams()
      .append('token', res?.token ?? '')
      .append('email', res.email)
      .append('password', res.password)
      .append('password_confirm', res.confirmPassword);
    return this.#http.post(`${this.#URL}/changepass`, httpParams).pipe(
      tap((res: any) => {
        if (res?.token) {
          this.#tokenService.setToken({ token: res.token, user: res.user });
        }
      }),
      finalize(() => {
        this.#router.navigate(['home']);
      }),
    );
  }
}
