@if (filterService.isLoaded()) {
  <div class="w-full bg-gray-100 py-5 px-2 lg:px-8 min-h-12">
    <div class="flex flex-col xl:flex-row w-full h-full">
      <div class="lg:flex gap-2 lg:w-full lg:flex-wrap grid grid-cols-2">
        <app-column-dropdown
          [label]="'pilot'"
          [options]="filterService.actorFilter()"
          [activefilter]="pilotList$$()"
          (remove)="removePilotFilter($event)"
          (select)="addPilotFilter($event)"
          class="min-w-36"
        />

        <app-column-dropdown
          [label]="'lineage'"
          [options]="filterService.lineageFilter()"
          [activefilter]="lineageList$$()"
          (remove)="removeLineageFilter($event)"
          (select)="addLineageFilter($event)"
          class="min-w-36"
        />

        <app-column-dropdown
          [label]="'nature'"
          [options]="filterService.natureFilter()"
          [activefilter]="natureList$$()"
          (remove)="removeNatureFilter($event)"
          (select)="addNatureFilter($event)"
          class="min-w-36"
        />

        <app-column-dropdown
          [label]="'severity'"
          [options]="filterService.severityFilter()"
          [activefilter]="severityList$$()"
          (remove)="removeSeverityFilter($event)"
          (select)="addSeverityFilter($event)"
          class="min-w-36"
        />

        <app-status-filter
          class="col-span-2"
          [availableStatus]="filterService.statusFilter()"
          [activefilter]="statusList$$()"
          (filterStatus)="addStatusFilter($event)"
          (removeAll)="removeAllFilters()"
        />
      </div>
    </div>
  </div>
}
