import { Component, input, output } from '@angular/core';
import { TextShorterPipe } from '../../../../pipes/text-shorter.pipe';
import { IconStatusPipe } from '../../../../pipes/icon-status.pipe';

@Component({
  selector: 'app-badge-list',
  standalone: true,
  imports: [TextShorterPipe, IconStatusPipe],
  templateUrl: './badge-list.component.html',
  styleUrl: './badge-list.component.scss',
})
export class BadgeListComponent {
  list = input<any[]>([]);
  remove = output<any>();
}
