import { Component, inject, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectService } from '../../../core/services/project.service';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { ColumnDropdownComponent } from './component/column-dropdown/column-dropdown.component';
import { StatusFilterComponent } from './component/status-filter/status-filter.component';
import { BadgeListComponent } from './component/badge-list/badge-list.component';
import { NavigationStateService } from '../../../core/services/navigation-state.service';
import { FiltersService } from '../../../core/services/filters.service';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    TranslateModule,
    JsonPipe,
    ColumnDropdownComponent,
    AsyncPipe,
    StatusFilterComponent,
    BadgeListComponent,
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
})
export class FiltersComponent {
  navigationStateService = inject(NavigationStateService);
  readonly #projectService = inject(ProjectService);
  readonly filterService = inject(FiltersService);

  status = signal<number[]>([]);

  pilotList$$ = signal<number[]>([]);
  lineageList$$ = signal<number[]>([]);
  natureList$$ = signal<number[]>([]);
  severityList$$ = signal<number[]>([]);
  statusList$$ = signal<number[]>([]);

  addPilotFilter(item: any) {
    this.pilotList$$.set([...this.pilotList$$(), item.id]);
    this.filterService.activeFilter$$.set([
      ...this.filterService.activeFilter$$(),
      item,
    ]);
    this.#projectService.setPilots(this.pilotList$$());
  }

  removePilotFilter(item: any) {
    this.pilotList$$.set(this.pilotList$$().filter((e) => e !== item.id));
    this.filterService.activeFilter$$.set(
      this.filterService.activeFilter$$().filter((e) => e.id !== item.id),
    );
    this.#projectService.setPilots(this.pilotList$$());
  }

  addLineageFilter(item: any) {
    this.lineageList$$.set([...this.lineageList$$(), item.id]);
    this.filterService.activeFilter$$.set([
      ...this.filterService.activeFilter$$(),
      item,
    ]);
    this.#projectService.setLineages(this.lineageList$$());
    this.filterService.activeLineage.set(
      this.filterService.activeFilter$$().map((e) => e.id),
    );
  }

  removeLineageFilter(item: any) {
    this.lineageList$$.set(this.lineageList$$().filter((e) => e !== item.id));
    this.filterService.activeFilter$$.set(
      this.filterService.activeFilter$$().filter((e) => e.id !== item.id),
    );
    this.#projectService.setLineages(this.lineageList$$());

    this.filterService.activeLineage.set(
      this.filterService.activeFilter$$().map((e) => e.id),
    );
  }

  addNatureFilter(item: any) {
    this.natureList$$.set([...this.natureList$$(), item.id]);
    this.filterService.activeFilter$$.set([
      ...this.filterService.activeFilter$$(),
      item,
    ]);
    this.#projectService.setNatures(this.natureList$$());
  }

  removeNatureFilter(item: any) {
    this.natureList$$.set(this.natureList$$().filter((e) => e !== item.id));
    this.filterService.activeFilter$$.set(
      this.filterService.activeFilter$$().filter((e) => e.id !== item.id),
    );
    this.#projectService.setNatures(this.natureList$$());
  }

  addSeverityFilter(item: any) {
    this.severityList$$.set([...this.severityList$$(), item.id]);
    this.filterService.activeFilter$$.set([
      ...this.filterService.activeFilter$$(),
      item,
    ]);
    this.#projectService.setGravities(this.severityList$$());
  }

  removeSeverityFilter(item: any) {
    this.severityList$$.set(this.severityList$$().filter((e) => e !== item.id));
    this.filterService.activeFilter$$.set(
      this.filterService.activeFilter$$().filter((e) => e.id !== item.id),
    );
    this.#projectService.setGravities(this.severityList$$());
  }

  addStatusFilter(item: any) {
    item = { id: item, name: item, type: 'status' };

    if (this.statusList$$().includes(item.id)) {
      this.removeFromFilter(item);
      return;
    }

    this.statusList$$.set([...this.statusList$$(), item.id]);
    this.filterService.activeFilter$$.set([
      ...this.filterService.activeFilter$$(),
      item,
    ]);
    this.#projectService.setStatus(this.statusList$$());
  }

  removeStatusFilter(item: any) {
    this.statusList$$.set(this.statusList$$().filter((e) => e !== item.id));
    this.filterService.activeFilter$$.set(
      this.filterService.activeFilter$$().filter((e) => e.id !== item.id),
    );
    this.#projectService.setStatus(this.statusList$$());
  }

  removeFromFilter(item: { name: string; id: number; type: string }) {
    switch (item.type) {
      case 'pilot':
        this.removePilotFilter(item);
        break;
      case 'lineage':
        this.removeLineageFilter(item);
        break;
      case 'nature':
        this.removeNatureFilter(item);
        break;
      case 'severity':
        this.removeSeverityFilter(item);
        break;
      case 'status':
        this.removeStatusFilter(item);
    }
  }

  removeAllFilters() {
    this.pilotList$$.set([]);
    this.lineageList$$.set([]);
    this.natureList$$.set([]);
    this.severityList$$.set([]);
    this.statusList$$.set([]);
    this.filterService.activeFilter$$.set([]);
    this.filterService.activeLineage.set(
      this.filterService.activeFilter$$().map((e) => e.id),
    );
    this.#resetAllFilterFromService();
  }

  #resetAllFilterFromService() {
    this.#projectService.setPilots(this.pilotList$$());
    this.#projectService.setLineages(this.lineageList$$());
    this.#projectService.setNatures(this.natureList$$());
    this.#projectService.setGravities(this.severityList$$());
    this.#projectService.setStatus(this.statusList$$());
  }
}
